<template>
  <div>
    <div v-if="isLoading">
      <section class="mb-10">
        <div class="flex mb-5">
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="25%"
          />
          <skeleton-loader
            class="ml-auto opacity-10 rounded"
            height="30px"
            width="60px"
          />
          <skeleton-loader
            class="ml-4 opacity-10 rounded"
            height="30px"
            width="60px"
          />
        </div>
        <skeleton-loader
          class="opacity-10 rounded"
          height="70px"
          width="100%"
        />
      </section>

      <section class="mb-10">
        <div class="flex mb-5">
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="25%"
          />
          <skeleton-loader
            class="ml-auto opacity-10 rounded"
            height="30px"
            width="60px"
          />
        </div>
        <skeleton-loader
          class="mb-5 opacity-10 rounded"
          height="70px"
          width="100%"
        />
        <skeleton-loader
          class="opacity-10 rounded"
          height="40px"
          width="100%"
        />
      </section>

      <section class="mb-10">
        <div class="flex mb-5">
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="25%"
          />
          <skeleton-loader
            class="ml-auto opacity-10 rounded"
            height="30px"
            width="60px"
          />
        </div>
        <skeleton-loader
          class="opacity-10 rounded"
          height="70px"
          width="100%"
        />
      </section>

      <section class="mb-10">
        <div class="flex mb-5">
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="25%"
          />
          <skeleton-loader
            class="ml-auto opacity-10 rounded"
            height="30px"
            width="60px"
          />
        </div>
        <skeleton-loader
          class="mb-5 opacity-10 rounded"
          height="70px"
          width="100%"
        />
        <div class="flex gap-5">
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="60px"
          />
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="60px"
          />
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="60px"
          />
          <skeleton-loader
            class="opacity-10 rounded"
            height="30px"
            width="60px"
          />
        </div>
      </section>
    </div>

    <div v-else class="flex flex-col gap-10 mb-40">
      <div>
        <div class="flex flex-wrap gap-5 items-center mb-5">
          <h6 class="font-bold text-base">Employment Status</h6>
          <div class="inline-flex gap-2 md:ml-auto">
            <div
              v-if="location"
              class="badge badge-blue-soft-outline uppercase"
            >
              {{ location }}
            </div>
            <div v-if="activityType" class="badge badge-blue-soft-outline">
              {{ activityType }}
            </div>
            <button
              type="button"
              class="btn btn-sm btn-blue"
              @click.prevent="getAnalyses"
            >
              Re-Analyze
            </button>
          </div>
        </div>

        <div class="boxes">
          <div class="box">
            <span class="mr-2">Employment</span>
            <div class="badge badge-yellow" v-if="isSelfEmployed">
              Self Employed
            </div>
            <div class="badge badge-green" v-else>Employed</div>
          </div>
          <div class="box">
            <span class="mr-2">Work email</span>
            <div
              class="inline-flex items-center"
              v-if="validateUser('work_email', staff)"
            >
              <div class="badge badge-green font-bold">
                {{ workplaceEmail }}
              </div>
            </div>
            <div
              class="badge badge-yellow"
              v-else-if="validateUser('work_email_trial', staff)"
            >
              Incomplete
            </div>
            <div class="badge badge-red" v-else>Not Verified</div>
          </div>
          <div class="box">
            <span class="mr-2">Salary / Income</span>
            <div v-if="statementProfile.salaryAmount" class="badge badge-green">
              Detected
            </div>
            <div v-else-if="!hasCreditSummary" class="badge badge-gray">
              ---
            </div>
            <div v-else class="badge badge-red">
              Undetected
            </div>
          </div>
          <div class="box" v-if="statementProfile.salaryAmount">
            <span class="mr-2">Salary amount</span>
            <div class="badge badge-green">
              ₦ {{ statementProfile.salaryAmount | currency }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasStatementAnalysis">
        <div class="flex items-center mb-5">
          <h6 class="font-bold text-base">Statement Analysis</h6>
          <div class="inline-flex ml-auto">
            <div class="badge badge-blue-soft-outline font-medium mr-3">
              <!-- <span class="inline-flex mr-1 text-xl">
                <ion-icon name="checkmark-circle" />
              </span> -->
              <span v-if="isExpress">Express</span>
              <span v-else-if="bankStatementMethod">
                {{ bankStatementMethod | sentenceCase }}
              </span>
              <span v-else>Manual</span>
            </div>
            <button
              class="btn btn-sm btn-blue"
              :disabled="statementDownload.loading"
              @click.prevent="downloadStatementPdf"
            >
              <sm-loader-white v-if="statementDownload.loading" />
              <span v-else>View</span>
            </button>
          </div>
        </div>
        <div class="boxes">
          <div class="box">
            <span class="font-medium mr-2">Last updated</span>
            <span class="font-medium text-blue-400">
              {{ statementProfile.lastUpdated | fromNow }}
            </span>
          </div>
          <div class="box">
            <span class="font-medium mr-2">Salary detected</span>
            <span class="font-medium text-blue-400">
              {{ statementProfile.salaryFrequency }}
              {{ 'month' | toPlural(statementProfile.salaryFrequency) }}
            </span>
          </div>
          <div class="box">
            <span class="font-medium mr-2">Recent salary</span>
            <span class="font-medium text-blue-400">
              {{ statementProfile.lastSalary | fromNow }}
            </span>
          </div>
          <div class="box">
            <span class="mr-2">-</span>
          </div>
        </div>
        <div class="boxes mb-5">
          <div class="box">
            <span class="font-medium mr-2">Average credit</span>
            <span class="font-medium text-blue-400">
              ₦ {{ statementProfile.averageCredit | currency }}
            </span>
          </div>
          <div class="box">
            <span class="font-medium mr-2">Average debit</span>
            <span class="font-medium text-blue-400">
              ₦ {{ statementProfile.averageDebit | currency }}
            </span>
          </div>
          <div class="box">
            <span class="font-medium mr-2">Total credit</span>
            <span class="font-medium text-blue-400">
              ₦ {{ statementProfile.totalCredit | currency }}
            </span>
          </div>
          <div class="box">
            <span class="font-medium mr-2">Total debit</span>
            <span class="font-medium text-blue-400">
              ₦ {{ statementProfile.totalDebit | currency }}
            </span>
          </div>
        </div>
      </div>
      <div class="gap-2" v-else>
        <div class="alert alert-orange py-5 px-5">
          <span class="alert-icon">
            <ion-icon name="alert" />
          </span>
          <span>No Statement Analysis Available</span>
        </div>
        <!-- <button type="button" class="btn btn-blue">
          <sm-loader-white v-if="0" />
          <span v-else>Retry Statement Analysis</span>
        </button> -->
      </div>
      <div
        class="bg-gray-200 flex flex-wrap 2xl:flex-no-wrap items-center gap-5 px-4 py-3"
      >
        <form
          class="flex flex-wrap flex-grow 2xl:flex-no-wrap gap-2 items-center w-full 2xl:w-auto"
          @submit.prevent="confirmMbsStatement"
        >
          <div class="flex flex-wrap md:flex-no-wrap gap-2 w-full sm:w-7/12">
            <form-group
              placeholder="Ticket ID"
              class="form-group-sm mb-0 w-full"
              name="ticket_id"
              v-model="mbsForm.data.ticket_id.value"
              :form="mbsForm"
            />
            <form-group
              placeholder="passcode"
              class="form-group-sm mb-0 w-full"
              name="password"
              v-model="mbsForm.data.password.value"
              :form="mbsForm"
            />
          </div>
          <button
            class="btn btn-sm btn-blue-outline"
            type="submit"
            :disabled="mbsForm.loading"
          >
            Request
          </button>
        </form>
        <button
          type="button"
          class="btn btn-sm btn-blue-outline 2xl:ml-auto"
          :disabled="documentRequestForm.loading"
          @click.prevent="requestBankStatement"
        >
          <sm-loader v-if="documentRequestForm.loading" />
          <span v-else>Doc Request</span>
        </button>
      </div>

      <div v-if="hasCreditSummary">
        <div class="flex items-center mb-5">
          <h6 class="font-bold text-base">Credit History Checks</h6>
          <div class="inline-flex gap-3 ml-auto">
            <button class="btn btn-sm btn-blue" @click="getCreditReportPdf">
              View
            </button>
          </div>
        </div>
        <div class="boxes">
          <div class="box">
            <div>
              <span class="block font-medium mb-1 mr-2">Last accessed</span>
              <span class="font-medium text-blue-400">
                {{ creditSummary.lastAccessed | fromNow }}
              </span>
            </div>
          </div>
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2">Closed loans</span>
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.closedLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.closedLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2">Open loans</span>
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.openLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.openLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2">Lost loans</span>
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.lostLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.lostLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="boxes">
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2">Doutbful loans</span>
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.doubtfulLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.doubtfulLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2"
                >Sub-Standard loans</span
              >
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.subStandardLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.subStandardLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="w-full">
              <span class="block font-medium mb-1 mr-2">Total loans</span>
              <div class="flex">
                <span class="font-medium">
                  {{ creditSummary.totalLoans }}
                </span>
                <span class="ml-auto">
                  ₦ {{ creditSummary.totalLoansAmount || 0 | currency }}
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <div>
              <span class="block font-medium mb-1">-</span>
              <span class="block">-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-2" v-else>
        <div class="alert alert-orange py-5 px-5">
          <span class="alert-icon">
            <ion-icon name="alert" />
          </span>
          <span>No Credit Summary Available</span>
        </div>
        <!-- <button type="button" class="btn btn-blue">
          <sm-loader-white v-if="0" />
          <span v-else>Retry Credit Check</span>
        </button> -->
      </div>

      <div v-if="activityTypeIsLoan">
        <div class="flex flex-wrap gap-5 items-center mb-5">
          <div class="flex flex-wrap items-center">
            <h6 class="font-bold text-base mr-2">Documents</h6>
            <div class="flex items-center">
              <span class="inline-flex mr-1 text-blue-500 text-xl">
                <ion-icon name="alert-circle" />
              </span>
              <span class="text-sm">
                Note: View all documents before approving.
              </span>
            </div>
          </div>
          <div class="inline-flex gap-3 xl:ml-auto">
            <div
              v-if="location"
              class="badge badge-blue-soft-outline uppercase"
            >
              {{ location }}
            </div>
          </div>
        </div>
        <div class="boxes">
          <div class="box">
            <span
              class="inline-flex mr-2 text-xl"
              :class="govtId ? 'text-green-500' : 'text-red-500'"
            >
              <ion-icon :name="govtId ? 'checkmark-circle' : 'close-circle'" />
            </span>
            <span class="font-medium mr-2">Govt ID</span>
            <a
              v-show="govtId"
              href="#!"
              class="font-medium text-blue-500 text-sm"
              @click.prevent="viewDocument(govtId)"
            >
              View
            </a>
          </div>
          <div class="box">
            <span
              class="inline-flex mr-2 text-xl"
              :class="workId ? 'text-green-500' : 'text-red-500'"
            >
              <ion-icon :name="workId ? 'checkmark-circle' : 'close-circle'" />
            </span>
            <span class="font-medium mr-2">Work ID</span>
            <a
              v-show="workId"
              href="#!"
              class="font-medium text-blue-500 text-sm"
              @click.prevent="viewDocument(workId)"
            >
              View
            </a>
          </div>
          <div class="box">
            <span
              class="inline-flex mr-2 text-xl"
              :class="manualBankStatement ? 'text-green-500' : 'text-red-500'"
            >
              <ion-icon
                :name="
                  manualBankStatement ? 'checkmark-circle' : 'close-circle'
                "
              />
            </span>
            <span class="font-medium mr-2">Bank Statement</span>
            <a
              v-show="manualBankStatement"
              href="#!"
              class="font-medium text-blue-500 text-sm"
              @click.prevent="viewDocument(manualBankStatement)"
            >
              View
            </a>
          </div>
          <div class="box">
            <span
              class="inline-flex mr-2 text-xl"
              :class="
                additionalDocuments.length ? 'text-green-500' : 'text-red-500'
              "
            >
              <ion-icon
                :name="
                  additionalDocuments.length
                    ? 'checkmark-circle'
                    : 'close-circle'
                "
              />
            </span>
            <span class="font-medium mr-2">Additional Docs</span>
            <a
              v-show="additionalDocuments.length"
              href="#!"
              class="font-medium text-blue-500 text-sm"
              @click.prevent="viewDocument(additionalDocuments[0])"
            >
              View
            </a>
          </div>
        </div>
      </div>

      <div v-if="loanbotDecision">
        <div class="flex items-center mb-5">
          <h6 class="font-bold text-base">LoanBot’s Recommendation</h6>
        </div>
        <div class="flex flex-row flex-wrap xl:flex-no-wrap gap-2">
          <div class="boxes w-full" :class="!shouldDecline && 'xl:w-1/3'">
            <div
              class="box md:w-full"
              :class="
                shouldApprove ? 'box-green' : shouldDecline ? 'box-red' : ''
              "
            >
              <div class="font-medium mr-2">Decision</div>
              <div
                class="inline-flex items-center"
                :class="
                  shouldApprove
                    ? 'text-green-500'
                    : shouldDecline
                    ? 'text-red-500'
                    : ''
                "
              >
                <div class="inline-flex mr-1 text-xl">
                  <ion-icon
                    :name="
                      shouldApprove
                        ? 'checkmark-circle'
                        : shouldDecline
                        ? 'close-circle'
                        : 'remove-circle'
                    "
                  />
                </div>
                <div class="font-medium">
                  {{
                    shouldApprove
                      ? 'Approve'
                      : shouldDecline
                      ? 'Decline'
                      : 'Undecided'
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="shouldDecline"
              class="box"
              :class="
                shouldApprove ? 'box-green' : shouldDecline ? 'box-red' : ''
              "
              style="border: none"
            >
              <span class="font-medium mr-2 whitespace-no-wrap truncate">
                {{ creditSummary.remark }}
              </span>
              <span
                class="cursor-pointer inline-flex text-gray-500 text-xl"
                @click.prevent="$refs.declinationReasonModal.open"
              >
                <ion-icon name="alert-circle-outline" />
              </span>
            </div>
          </div>
          <div class="boxes w-full" :class="!shouldDecline && 'xl:w-2/3'">
            <div class="box">
              <span class="font-medium mr-2">
                {{ hasLoanRequest ? 'Loan request' : 'Card Limit' }}
              </span>
              <span class="font-medium text-blue-400">
                {{ isRequestingLoan | formatAmount }}
              </span>
            </div>
            <div class="box">
              <span class="font-medium mr-2">Loan limit</span>
              <span class="font-medium text-blue-400">
                {{ loanLimit | formatAmount }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasStatementAnalysis">
        <div class="flex items-center mb-5">
          <h6 class="font-bold text-base">Name Verification</h6>
        </div>
        <div
          class="flex flex-row flex-wrap 2xl:flex-no-wrap gap-2 items-center mb-5"
        >
          <div class="w-full">
            <div class="boxes mb-3">
              <div class="box md:w-full xl:w-1/2">
                <span class="font-medium mr-2">Name on Profile</span>
                <span class="font-medium text-blue-400">
                  {{ nameOnProfile | sentenceCase }}
                </span>
              </div>
              <div class="box md:w-full xl:w-1/2">
                <span class="font-medium mr-2">Name on BVN</span>
                <span class="font-medium text-blue-400">
                  {{ nameOnBvn | lowerCase | sentenceCase }}
                </span>
              </div>
            </div>
            <div class="boxes">
              <div class="box w-full xl:w-1/2">
                <span class="font-medium mr-2">Name on Bank Statement</span>
                <span class="font-medium text-blue-400">
                  {{ nameOnBankStatement }}
                </span>
              </div>
              <!-- <div class="box md:w-full xl:w-1/2">
                <span class="font-medium mr-2">Name on Credit report </span>
                <span class="font-medium text-blue-400">
                  {{ nameOnCreditReport }}
                </span>
              </div> -->
            </div>
          </div>
          <div class="flex gap-2" v-if="isNotProcessed">
            <button class="btn btn-red" @click.prevent="confirmDeclination">
              No
            </button>
            <button class="btn btn-blue">Yes</button>
          </div>
        </div>
      </div>

      <div v-if="hasDataToProcess">
        <div
          class="bg-gray-200 flex flex-wrap items-center gap-5 mb-5 px-4 py-3"
        >
          <div class="font-bold text-sm mr-2">Admin Attention</div>
          <form
            class="inline-flex flex-wrap 2xl:flex-no-wrap items-center gap-2 ml-auto"
            @submit.prevent="updateAnalyses"
          >
            <div
              class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-6 gap-2 w-full"
            >
              <div class="col-span-1">
                <div
                  class="bg-blue-400 py-2 text-center text-white text-xs rounded-sm"
                >
                  {{ inputedSalary | formatAmount }}
                </div>
              </div>
              <div class="col-span-1">
                <div
                  class="bg-green-500 py-2 text-center text-white text-xs rounded-sm"
                >
                  {{ detectedSalary }}
                </div>
              </div>
              <div class="col-span-1">
                <form-group
                  v-model="analysisUpdateForm.data.detected_salary.value"
                  :form="analysisUpdateForm"
                  name="detected_salary"
                  placeholder="Salary detected"
                  class="form-group-sm mb-0 w-full"
                />
              </div>
              <div class="col-span-1">
                <form-group
                  v-model="analysisUpdateForm.data.registered_salary_date.value"
                  :form="analysisUpdateForm"
                  name="registered_salary_date"
                  placeholder="Reg. Salary Date "
                  class="form-group-sm mb-0 w-full"
                />
              </div>
              <div class="col-span-1">
                <form-group
                  v-model="analysisUpdateForm.data.last_salary_date.value"
                  :form="analysisUpdateForm"
                  name="last_salary_date"
                  placeholder="last salary date"
                  class="form-group-sm mb-0 w-full"
                />
              </div>
              <div class="col-span-1">
                <form-group
                  v-model="analysisUpdateForm.data.loan_amount.value"
                  :form="analysisUpdateForm"
                  name="loan_amount"
                  placeholder="Loan amount"
                  class="form-group-sm mb-0 w-full"
                />
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-blue btn-sm"
              :disabled="!enableAdminActionButton"
            >
              Okay
            </button>
          </form>
        </div>
      </div>

      <div>
        <div class="flex flex-wrap items-center gap-4">
          <div class="inline-flex flex-wrap items-center gap-2">
            <template v-if="activityType === 'CC'">
              <div
                v-if="isNotProcessed"
                class="inline-flex flex-wrap items-center gap-2"
              >
                <button
                  type="button"
                  class="btn btn-orange"
                  @click.prevent="confirmSelfEmployedFlag"
                >
                  Self Employed
                </button>
                <button
                  type="button"
                  class="btn btn-yellow"
                  @click.prevent="modifyApproval"
                >
                  Modify
                </button>
                <button
                  type="button"
                  class="btn btn-green"
                  @click.prevent="confirmApproval"
                >
                  Accept
                </button>
              </div>
              <div v-if="hasLimitIncreaseRequest || canIncreaseLimits">
                <button
                  type="button"
                  class="btn btn-green"
                  @click="$refs.increaseLimit.open"
                >
                  Increase Limit
                </button>
              </div>
              <button
                type="button"
                class="btn btn-red"
                @click.prevent="confirmDeclination"
              >
                Decline
              </button>
            </template>
            <template v-else-if="activityType === 'Loan'">
              <button
                type="button"
                class="btn btn-blue"
                @click.prevent="$refs.modifyLoanRef.open()"
              >
                Modify Loan
              </button>
              <button
                type="button"
                class="btn btn-green"
                :disabled="loanResource.loading"
                @click.prevent="approveLoanRequest"
              >
                Approve Loan Request
              </button>
              <button
                type="button"
                class="btn btn-red"
                :disabled="loanResource.loading"
                @click.prevent="declineLoanRequest"
              >
                Decline Loan Request
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>

    <modal
      class-name="flex flex-col pb-5 w-full sm:max-w-800px xl:max-w-1000px max-h-full h-700px"
      ref="documentViewer"
    >
      <div class="flex gap-5 items-center mb-5">
        <h3 class="font-bold text-xl">
          {{ getDocumentName(documentPreview.data) }}
        </h3>
        <div class="flex gap-4 ml-auto">
          <button
            type="button"
            class="btn btn-red"
            :disabled="
              getProcess(documentPreview.data) &&
                getProcess(documentPreview.data).loading
            "
            @click.prevent="invalidateDocument(documentPreview.data)"
          >
            <sm-loader-white
              v-if="
                getProcess(documentPreview.data) &&
                  getProcess(documentPreview.data).loading
              "
            />
            <template v-else>
              <span class="flex mr-2 text-xl">
                <ion-icon name="close" />
              </span>
              <span>Reject</span>
            </template>
          </button>
          <!-- <button class="btn btn-green">
            <span class="flex mr-2 text-xl">
              <ion-icon name="checkmark" />
            </span>
            <span>Approve</span>
          </button> -->
        </div>
      </div>
      <div class="bg-blue-200 flex-grow mb-3 relative rounded">
        <div
          class="grid h-full place-items-center w-full"
          v-if="documentPreview.loading"
        >
          <sm-loader />
        </div>
        <iframe
          v-else-if="documentPreview.url"
          :src="documentPreview.url"
          frameborder="0"
          class="h-full w-full"
        />
        <button
          type="button"
          class="
            absolute bg-white border border-transparent duration-100 grid h-40px left-0 mx-2 outline-none place-items-center
            rounded-full top-1/2 transform -translate-y-1/2 transition-transform w-40px
            active:scale-110
            hover:bg-blue-200 hover:border-blue-500 hover:text-blue-500 focus:outline-none
          "
          @click.prevent="prevDocument"
        >
          <ion-icon name="chevron-back-outline" />
        </button>
        <button
          type="button"
          class="
            absolute bg-white border border-transparent duration-100 grid h-40px mx-2 outline-none place-items-center right-0
            rounded-full top-1/2 transform -translate-y-1/2 transition-transform w-40px
            active:scale-110
            hover:bg-blue-200 hover:border-blue-500 hover:text-blue-500 focus:outline-none
          "
          @click.prevent="nextDocument"
        >
          <ion-icon name="chevron-forward-outline" />
        </button>
      </div>
      <div class="text-sm">
        {{ getDocumentIndex() + 1 }} of {{ documents.length }} items
      </div>
    </modal>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="declinationReasonModal"
    >
      <h3 class="font-bold mb-10 text-xl">Declination Reasons</h3>

      <ul class="mb-10">
        <li>
          {{ creditSummary.remark }}
        </li>
      </ul>

      <div class="flex items-center gap-4 mt-auto">
        <button
          type="button"
          class="btn btn-gray btn-md"
          @click.prevent="$refs.declinationReasonModal.close"
        >
          Close
        </button>
      </div>
    </modal>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="declinationModal"
    >
      <h3 class="font-bold mb-5 text-xl">Reason for declining</h3>
      <form
        @submit.prevent="declineApplication"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="declinationForm.data.comments.value"
          :form="declinationForm"
          name="comments"
          type="textarea"
        >
          Type a reason here
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.declinationModal.close"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-blue btn-md w-full">
            Submit
          </button>
        </div>
      </form>
    </modal>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="approvalModal"
    >
      <h3 class="font-bold mb-5 text-xl">Confirm Approval</h3>
      <form
        @submit.prevent="approveApplication"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="approvalForm.data.comments.value"
          :form="approvalForm"
          name="comments"
          type="textarea"
        >
          Enter Comments
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.approvalModal.close"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="approvalForm.loading"
          >
            <sm-loader-white v-if="approvalForm.loading" />
            <span v-else>Approve</span>
          </button>
        </div>
      </form>
    </modal>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="selfEmployedModal"
    >
      <h3 class="font-bold mb-3 text-xl">Process a Self Employed</h3>

      <p class="mb-10 text-gray-500 text-sm">
        This approves the user's credit application and assigns a credit limit
        of ₦2,500.00
      </p>

      <form
        @submit.prevent="approveAsSelfEmployed"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="selfEmployedForm.data.comments.value"
          :form="selfEmployedForm"
          name="comments"
          type="textarea"
        >
          Enter Comments
        </form-group>

        <template v-if="getFormError(selfEmployedForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">
              <ion-icon name="alert" />
            </span>
            <span>{{ getFormError(selfEmployedForm) }}</span>
          </div>
        </template>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.selfEmployedModal.close"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="selfEmployedForm.loading"
          >
            <sm-loader-white v-if="selfEmployedForm.loading" />
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </modal>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px"
      ref="approvalModificationModal"
    >
      <h3 class="font-bold mb-2 text-xl">Approve Application</h3>
      <p class="mb-10 text-gray-600">
        Update the user's credit and loan parameters
      </p>
      <form
        @submit.prevent="approveApplication"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="approvalForm.data.credit_limit.value"
          :form="approvalForm"
          name="credit_limit"
          type="money"
        >
          Enter Credit Limit
        </form-group>

        <form-group
          v-model="approvalForm.data.standard_loan_limit.value"
          :form="approvalForm"
          name="standard_loan_limit"
          type="money"
        >
          Enter Standard Loan Limit
        </form-group>

        <form-group
          v-model="approvalForm.data.standard_loan_interest_rate.value"
          :form="approvalForm"
          name="standard_loan_interest_rate"
          type="number"
        >
          Enter Interest Rate
        </form-group>

        <form-group
          v-model="approvalForm.data.comments.value"
          :form="approvalForm"
          name="comments"
          type="textarea"
        >
          Type comments here
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.approvalModificationModal.close"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="approvalForm.loading"
          >
            <sm-loader-white v-if="approvalForm.loading" />
            <span v-else>Approve</span>
          </button>
        </div>
      </form>
    </modal>

    <increase-limit
      v-if="staff"
      ref="increaseLimit"
      :selected-user="staff"
      @success="$emit('success')"
    />

    <modify-loan :loan="loanRequest" ref="modifyLoanRef" v-if="loanRequest" />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  name: 'LoanbotAnalysis',
  components: {
    increaseLimit: () => import('../IncreaseLimit'),
    modifyLoan: () => import('./components/ModifyLoan')
  },
  props: {
    staff: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      analysisUpdateForm: this.$options.basicForm([
        { name: 'detected_salary', rules: 'nullable|number|min:10' },
        {
          name: 'registered_salary_date',
          rules: 'nullable|number|min:1|max:30'
        },
        { name: 'last_salary_date', rules: 'nullable' },
        { name: 'loan_amount', rules: 'nullable|number|min:2500' }
      ]),
      approvalForm: this.$options.basicForm([
        { name: 'credit_limit', rules: 'required|number|min:2500' },
        { name: 'standard_loan_limit', rules: 'required|number|min:2500' },
        {
          name: 'standard_loan_interest_rate',
          rules: 'required|numeric|max:100|min:0.1'
        },
        { name: 'comments', rules: 'required' }
      ]),
      configurations: this.$options.resource(null),
      declinationForm: this.$options.basicForm(['comments']),
      documentPreview: this.$options.resource(null, { url: null }),
      documentRequestForm: this.$options.basicForm(),
      invalidationProcesses: [],
      loanbot: {
        statementAnalysis: this.$options.resource(null),
        creditSummary: this.$options.resource(null)
      },
      loanResource: this.$options.resource(null),
      selfEmployedForm: this.$options.basicForm(['comments']),
      statementDownload: this.$options.resource(),
      mbsForm: this.$options.basicForm([
        { name: 'ticket_id', rules: 'required' },
        { name: 'password', rules: 'required' }
      ])
    };
  },
  computed: {
    documents() {
      return [
        this.govtId,
        this.workId,
        this.creditReport,
        this.manualBankStatement,
        ...this.additionalDocuments
      ].filter(doc => !!doc);
    },
    additionalDocuments() {
      return this.staff?.documents?.filter(doc => doc.type === 'custom') || [];
    },
    creditReport() {
      return this.getLatestDocument('credit_report');
    },
    govtId() {
      return this.getLatestDocument('govt_id');
    },
    workId() {
      return this.getLatestDocument('work_id');
    },
    manualBankStatement() {
      return this.staff?.documents
        ?.filter(
          document =>
            document.type === 'bank_statement' &&
            !document.url?.match(/mono|mbs|mobile|okra|ussd/)
        )
        ?.pop();
    },
    mbsData() {
      return [...this.staff?.my_bank_statements].pop();
    },
    bvn() {
      return this.staff?.profile?.bvn;
    },
    pdfUrl() {
      return `${this.$loanbotUrl}/report/api/credit/generate-customer-report/${this.bvn}`;
    },
    isExpress() {
      return this.validateUser('express', this.staff);
    },
    isSelfEmployed() {
      return this.validateUser('self_employed', this.staff);
    },
    location() {
      return this.staff?.profile?.state;
    },
    nameOnBankStatement() {
      return '';
    },
    nameOnBvn() {
      return `${this.staff?.bvn?.data?.firstName} ${this.staff?.bvn?.data?.middleName} ${this.staff?.bvn?.data?.lastName}`;
    },
    nameOnCreditReport() {
      return '';
    },
    workplaceEmail() {
      return this.staff?.official_email;
    },
    nameOnProfile() {
      return `${this.staff?.name} ${this.staff?.last_name}`;
    },
    staffId() {
      return this.staff?.id;
    },
    activityType() {
      if (this.hasLoanRequest) {
        return 'Loan';
      }
      if (
        this.isNotProcessed ||
        this.hasLimitIncreaseRequest ||
        this.canIncreaseLimits
      ) {
        return 'CC';
      }
      return null;
    },
    activityTypeIsLoan() {
      return this.activityType === 'Loan';
    },
    bankStatementMethod() {
      const statement = this.getLatestDocument('bank_statement');
      if (!statement) return false;

      switch (statement?.url) {
        case 'mono':
          return 'Mono';
        case 'okra':
          return 'Okra';
        case 'mbs':
        case 'mobile':
          return 'MBS';
        default:
          return 'Manual';
      }
    },
    expressVerification() {
      return this.staff?.express_verification;
    },
    hasCreditSummary() {
      return this.loanbot.creditSummary.data;
    },
    hasDataToProcess() {
      return this.hasCreditSummary || this.hasStatementAnalysis;
    },
    hasLimitIncreaseRequest() {
      return this.expressVerification?.status === 'pending';
    },
    loanRequest() {
      return this.staff?.loan?.find(loan => loan.status === 'pending');
    },
    hasLoanRequest() {
      return !!this.loanRequest;
    },
    isRequestingLoan() {
      return this.hasLoanRequest
        ? this.loanRequest?.requested_amount
        : this.creditSummary.creditLimit;
    },
    loanLimit() {
      return this.creditSummary.loanLimit;
    },
    hasStatementAnalysis() {
      return this.loanbot.statementAnalysis.data;
    },
    isLoading() {
      return (
        this.loanbot.statementAnalysis.loading ||
        this.loanbot.creditSummary.loading
      );
    },
    isNotProcessed() {
      return !this.staff?.profile?.status?.match(/activated|declined/);
    },
    isUndecided() {
      return this.loanbotDecision === 'undecided';
    },
    loanbotDecision() {
      return this.creditSummary?.decision;
    },
    shouldApprove() {
      return this.loanbotDecision === 'approved';
    },
    shouldDecline() {
      return this.loanbotDecision === 'declined';
    },
    standardLoanMultiplier() {
      if (this.isSelfEmployed) {
        return this.getConfiguration(
          'standard_loan_limit_multiplier_for_self_employed',
          2
        );
      }

      return this.getConfiguration(
        'standard_loan_limit_multiplier_for_employed',
        3
      );
    },
    canApproveOrDecline() {
      return (
        (this.isSuperRisk || this.isSuperAdmin) &&
        (this.staff?.profile?.status?.match(/verified/) ||
          this.staff?.profile?.status?.match(
            /activated|pre-approved|deactivated/
          ))
      );
    },
    canIncreaseLimits() {
      return (
        this.canApproveOrDecline && this.validateUser('approval', this.staff)
      );
    },
    creditSummary() {
      return {
        lastAccessed: this.loanbot.creditSummary.data?.performance_summary
          ?.updated_at,
        closedLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.closed_loans,
        closedLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.closed_loans,
        openLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.open_loans,
        openLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.open_loans,
        doubtfulLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.doubtful_loans,
        doubtfulLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.doubtful_loans,
        lostLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.lost_loans,
        lostLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.lost_loans,
        subStandardLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.sub_standard_loans,
        subStandardLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.sub_standard_loans,
        totalLoans: this.loanbot.creditSummary.data?.performance_summary
          ?.total_loans,
        totalLoansAmount: this.loanbot.creditSummary.data
          ?.performance_summary_amount?.total_loans,
        creditLimit: this.loanbot.creditSummary.data?.instant_approval?.amount,
        loanLimit: this.loanbot.creditSummary.data?.instant_approval
          ?.loan_limit,
        decision: this.loanbot.creditSummary.data?.instant_approval?.status,
        remark: this.loanbot.creditSummary.data?.instant_approval?.remark
      };
    },
    statementProfile() {
      return {
        lastUpdated: this.loanbot.statementAnalysis.data?.updated_at,
        salaryAmount: this.loanbot.statementAnalysis.data?.salary_amount,
        salaryFrequency: this.loanbot.statementAnalysis.data?.salary_frequency,
        lastSalary: this.loanbot.statementAnalysis.data?.last_salary_date,
        averageCredit: this.loanbot.statementAnalysis.data
          ?.monthly_average_credit,
        averageDebit: this.loanbot.statementAnalysis.data
          ?.monthly_average_debit,
        totalDebit: this.loanbot.statementAnalysis.data?.total_credit,
        totalCredit: this.loanbot.statementAnalysis.data?.total_credit
      };
    },
    detectedSalary() {
      const { formatAmount } = this.$options.filters;
      return this.statementProfile?.salaryAmount
        ? formatAmount(this.statementProfile?.salaryAmount)
        : 'No salary detected';
    },
    inputedSalary() {
      return this.staff?.profile?.salary;
    },
    enableAdminActionButton() {
      return (
        this.analysisUpdateForm.data.detected_salary.value ||
        this.analysisUpdateForm.data.registered_salary_date.value
      );
    }
  },
  beforeMount() {
    this.getAnalyses();
    this.getConfigurations();
  },
  methods: {
    confirmApproval() {
      this.$refs.approvalModal.open();
    },
    confirmDeclination() {
      this.$refs.declinationModal.open();
    },
    confirmSelfEmployedFlag() {
      this.$refs.selfEmployedModal.open();
    },
    modifyApproval() {
      this.$refs.approvalModificationModal.open();
    },
    mapParameters() {
      this.approvalForm.data.credit_limit.value =
        Number(this.creditSummary?.creditLimit) ||
        this.approvalForm.data.credit_limit.value;
      this.approvalForm.data.standard_loan_limit.value =
        Number(this.creditSummary?.loanLimit) ||
        Number(this.creditSummary?.creditLimit) * this.standardLoanMultiplier ||
        this.approvalForm.data.standard_loan_limit.value;
      this.approvalForm.data.standard_loan_interest_rate.value =
        this.getConfiguration('standard_loan_interest_rate') ||
        this.approvalForm.data.standard_loan_interest_rate.value;

      this.mbsForm.data.ticket_id.value = this.mbsData?.ticket_id || '';
      this.mbsForm.data.password.value = this.mbsData?.password || '';
    },

    createProcess(doc) {
      const PROCESS = this.$options.resource(doc);
      this.invalidationProcesses.push(PROCESS);
      return PROCESS;
    },
    getProcess(doc) {
      return this.invalidationProcesses.find(PROCESS => PROCESS.data === doc);
    },
    updateProcess(doc, cb = () => {}) {
      this.invalidationProcesses = this.invalidationProcesses.map(PROCESS => {
        if (PROCESS.data !== doc) {
          return PROCESS;
        }

        cb(PROCESS);
        return PROCESS;
      });
    },

    getDocumentIndex(doc) {
      if (!doc) {
        doc = this.documentPreview.data;
      }
      return this.documents.findIndex(d => d === doc);
    },
    getDocumentName(doc) {
      if (!doc) return '';

      switch (doc.type) {
        case 'bank_statement':
          return 'Bank Statement';
        case 'custom':
          return doc.filename;
        case 'credit_report':
          return 'Credit Report';
        case 'govt_id':
          return 'Government Issued ID';
        case 'work_id':
          return 'Work ID';
        default:
          return this.$options.filters.sentenceCase(
            this.$options.filters.fromSlug(doc.type)
          );
      }
    },
    getLatestDocument(type, cb = () => true) {
      return this.staff?.documents
        ?.filter(doc => doc.type === type && cb(doc))
        .pop();
    },
    nextDocument() {
      let index = this.getDocumentIndex(this.documentPreview.data);
      index = index < this.documents.length - 1 ? index + 1 : 0;
      this.viewDocument(this.documents[index]);
    },
    prevDocument() {
      let index = this.getDocumentIndex(this.documentPreview.data);
      index = index > 0 ? index - 1 : this.documents.length - 1;
      this.viewDocument(this.documents[index]);
    },
    viewDocument(doc) {
      this.documentPreview.setValue(doc);
      this.getDocumentSrc(doc);
      this.$refs.documentViewer.open();
    },
    getAnalyses() {
      this.getStatementAnalysis();
      this.getCreditSummary();
    },
    getConfiguration(name, alternative = null) {
      return (
        this.configurations.data?.find(
          configuration => configuration.name === name
        )?.value || alternative
      );
    },
    getCreditReportPdf() {
      window.open(this.pdfUrl, '_blank');
    },
    async getConfigurations() {
      this.configurations.loading = true;
      await this.sendRequest('admin.configurations.all', {
        success: response => {
          this.configurations.data = response.data.configurations;
          this.mapParameters();
        },
        error: error => {
          this.configurations.error = error;
        }
      });
      this.configurations.loading = false;
    },
    async getDocumentSrc(doc) {
      this.documentPreview.url = null;

      if (!doc) return;

      this.documentPreview.loading = false;
      await this.sendRequest('personal.documents.getUrl', doc.id, {
        success: response => {
          this.documentPreview.url = response.data.url;
        },
        error: error => {
          this.documentPreview.error = error;
        }
      });

      this.documentPreview.loading = false;
    },

    async getStatementAnalysis() {
      this.loanbot.statementAnalysis.loading = true;
      this.loanbot.statementAnalysis.error = null;
      await this.sendRequest(
        'admin.users.loanbot.getStatementAnalysis',
        this.staffId,
        {
          success: response => {
            this.loanbot.statementAnalysis.setValue(
              response.data.data.statement_analysis
            );
            this.mapParameters();
          },
          error: error => {
            console.log(error);
            this.loanbot.statementAnalysis.error = error;
          }
        }
      );
      this.loanbot.statementAnalysis.loading = false;
    },
    async downloadStatementPdf() {
      this.statementDownload.loading = true;
      await this.sendRequest(
        'admin.users.loanbot.downloadStatement',
        this.staffId,
        {
          axiosProps: {
            responseType: 'blob',
            contentType: 'application/pdf',
            headers: this.headers
          }
        }
      )
        .then(({ response }) => {
          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf'
          });
          saveAs(
            pdfBlob,
            `${this.staff.name}-${this.staff.last_name}-Bank-Statement.pdf`
          );
          this.downloading = false;
        })
        .catch(error => {
          this.statementDownload.error = error;
          console.log(error);
        });
      this.statementDownload.loading = false;
    },
    async getCreditSummary() {
      this.loanbot.creditSummary.loading = true;
      this.loanbot.creditSummary.error = null;
      await this.sendRequest(
        'admin.users.loanbot.getCreditSummary',
        this.staffId,
        {
          success: response => {
            this.loanbot.creditSummary.setValue(
              response.data.data.credit_summary
            );
            this.mapParameters();
          },
          error: error => {
            this.loanbot.creditSummary.error = error;
          }
        }
      );
      this.loanbot.creditSummary.loading = false;
    },

    async approveApplication() {
      if (!this.validateForm(this.approvalForm)) return;

      this.approvalForm.loading = true;
      this.approvalForm.error = null;
      await this.sendRequest('admin.users.approve', {
        data: {
          user_id: this.staff.id,
          ...this.getFormData(this.approvalForm)
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: "User's account has been activated",
            button: 'Okay'
          });
          this.approvalForm = this.resetForm(this.approvalForm);
          this.$emit('success');
        },
        error: error => {
          this.approvalForm.error = error;
          this.mapFormErrors(this.approvalForm, error.response?.data?.errors);
        }
      });
      this.approvalForm.loading = false;
    },
    async approveAsSelfEmployed() {
      if (!this.validateForm(this.selfEmployedForm)) return;

      this.selfEmployedForm.loading = true;
      this.selfEmployedForm.error = null;
      await this.sendRequest('admin.users.updateStatus', {
        data: {
          user_id: this.staff.id,
          status: 'self_employed',
          comments: this.selfEmployedForm.data.comments.value
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: "User's account has been approved for ₦2,500.00",
            button: 'Okay'
          });
          this.selfEmployedForm = this.resetForm(this.selfEmployedForm);
          this.$emit('success');
        },
        error: error => {
          this.selfEmployedForm.error = error;
          this.mapFormErrors(
            this.selfEmployedForm,
            error.response?.data?.errors
          );
        }
      });
      this.selfEmployedForm.loading = false;
    },
    async declineApplication() {
      if (!this.validateForm(this.declinationForm)) return;

      this.declinationForm.loading = true;
      this.declinationForm.error = null;
      await this.sendRequest('admin.users.decline', {
        data: {
          user_id: this.staff.id,
          ...this.getFormData(this.declinationForm)
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User application declined',
            button: 'Okay'
          });
          this.declinationForm = this.resetForm(this.declinationForm);
          this.$emit('success');
        },
        error: error => {
          this.declinationForm.error = error;
          this.mapFormErrors(
            this.declinationForm,
            error.response?.data?.errors
          );
        }
      });
      this.declinationForm.loading = false;
    },
    async requestBankStatement() {
      if (!this.validateForm(this.documentRequestForm)) return;

      this.documentRequestForm.loading = true;
      this.documentRequestForm.error = null;
      await this.sendRequest('admin.users.documents.request', this.staffId, {
        data: {
          document_type: 'bank_statement'
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Bank statements have invalidated and user has been notified',
            button: 'Okay'
          });
          this.documentRequestForm = this.resetForm(this.documentRequestForm);
          this.$emit('success');
        },
        error: error => {
          this.documentRequestForm.error = error;
          this.mapFormErrors(
            this.documentRequestForm,
            error.response?.data?.errors
          );
        }
      });
      this.documentRequestForm.loading = false;
    },
    async invalidateDocument(doc) {
      if (!doc) return;

      this.getProcess(doc) || this.createProcess(doc);
      this.updateProcess(doc, PROCESS => {
        PROCESS.error = true;
        PROCESS.loading = true;
      });
      await this.sendRequest(
        'admin.users.documents.invalidate',
        this.staffId,
        doc.id,
        {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Document Invalidated',
              button: 'Okay'
            });
            this.$refs.documentViewer.close();
            this.updateProcess(doc, PROCESS => (PROCESS.success = true));
            this.$emit('success');
          },
          error: error => {
            this.updateProcess(doc, PROCESS => {
              PROCESS.error = error;
            });
          }
        }
      );
      this.updateProcess(doc, PROCESS => (PROCESS.loading = false));
    },
    async approveLoanRequest() {
      this.loanResource.loading = true;
      await this.sendRequest(
        'admin.loans.personal.approve',
        this.loanRequest?.id,
        {
          success: response => {
            const {
              data: { loan }
            } = response.data;

            this.$success({
              title: 'Operation Successful',
              body: 'Loan Request Approved',
              button: 'View Offer Letter',
              onClose: () => {
                window.open(
                  this.$router.resolve({
                    name: 'offer-letter',
                    params: {
                      loanId: loan.id
                    }
                  }).href,
                  '_blank'
                );
              }
            });
            this.$emit('success');
          },
          error: error => console.log(error)
        }
      ).finally(() => (this.loanResource.loading = false));
    },
    async declineLoanRequest() {
      this.loanResource.loading = true;

      await this.sendRequest(
        'admin.loans.personal.decline',
        this.loanRequest?.id,
        {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Loan Request Declined',
              button: 'Okay'
            });
            this.$emit('success');
          },
          error: error => console.log(error)
        }
      ).finally(() => (this.loanResource.loading = false));
    },
    async confirmMbsStatement() {
      if (!this.validateForm(this.mbsForm)) return;

      this.mbsForm.loading = true;

      await this.sendRequest('admin.users.documents.confirmMbs', this.staffId, {
        data: this.getFormData(this.mbsForm),
        success: async () => {
          this.$success({
            title: 'Success!',
            body: 'Mbs Request Successful'
          });
          this.$emit('success');
          await this.getAnalyses();
        },
        error: error => {
          this.$error({
            title: 'Operation Failed',
            body: error?.response?.data?.message
          });
          this.mapFormErrors(this.mbsForm, error.response?.data?.errors);
        }
      }).finally((this.mbsForm.loading = false));
    },
    async updateAnalyses() {
      if (!this.validateForm(this.analysisUpdateForm)) return false;
      this.analysisUpdateForm.loading = true;
      this.analysisUpdateForm.error = null;

      await this.sendRequest(
        'admin.users.loanbot.updateAnalyses',
        this.staffId,
        {
          data: this.getFormData(this.analysisUpdateForm),
          success: async () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Analysis Updated',
              button: 'Okay'
            });
            this.resetForm(this.analysisUpdateForm);
            await this.getAnalyses();
            this.$emit('success');
          },
          error: error => {
            this.analysisUpdateForm.error = error;
            this.mapFormErrors(
              this.analysisUpdateForm,
              error?.response?.data?.errors
            );
          }
        }
      );

      this.analysisUpdateForm.loading = false;
    }
  }
};
</script>

<style lang="sass" scoped>
.boxes
  @apply flex flex-wrap items-center
  &:not(:first-child)
    margin-top: -1px
  .box
    @apply border border-blue-200 flex flex-grow h-full items-center p-4 text-xs w-full
    @media (min-width: theme('screens.md'))
      &:not(.md\:w-full)
        @apply w-full
    // @media (min-width: theme('screens.lg'))
    //   &:not(.lg\:w-full)
    //     @apply w-1/2
    @media (min-width: theme('screens.xl'))
      &:not(.xl\:w-full)
        @apply w-1/2
    @media (min-width: theme('screens.2xl'))
      &:not(.\2xl\:w-full)
        @apply w-1/4
    &-green
      @apply bg-green-100 border-green-500
    &-red
      @apply bg-red-100 border-red-500
    &:not(:first-child)
      @media (min-width: theme('screens.2xl'))
        border-left: none
    > :last-child:not(:first-child)
      @apply ml-auto
    .badge
      @apply text-xs
</style>
